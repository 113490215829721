@tailwind base;
@tailwind components;
@tailwind utilities;

/* Removes the blue highlight on taps in touch screen */
body {
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

.read-more-link {
  display: inline !important;
}

.r-s-image span {
  @apply relative;
}
.r-s-image span:before {
  @apply absolute left-0 -bottom-4;
  content: url(./../public/ScrumMasterFormVector.png);
}

.exit-content-img span {
  @apply relative inline-block;
}

.underline-image p {
  @apply relative;
}
.underline-image p:before {
  @apply absolute left-0 -bottom-4;
  content: url(./../public/Images/greenUnderline.png);
}

.zoom-in-out {
  animation: zoom-in-zoom-out 3s ease infinite;
}
.exit-content-img span:before {
  @apply absolute left-0 -bottom-2 w-full bg-no-repeat;
  content: "";
  background-image: url("https://d2o2utebsixu4k.cloudfront.net/blogs/blog-exit-bg.svg");
  height: 6px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

.f-s-m-image span {
  @apply relative;
}
.f-s-m-image span:before {
  @apply absolute left-0 -bottom-4;
  content: url(./../public/ScrumMasterFormVector.png);
}

.v3-coupon-text-shadow {
  /* text-shadow: -2px 0px black, -2.5px 0px #1cd34c; */
  background: linear-gradient(180deg, #27d400 -0.66%, #00cdff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 0px 0 #fff, 1px 0px 0 #000559;
}
/* purgecss start ignore */

body {
  font-family: 'Inter', sans-serif;
}
.blog-editor-content pre:not(.lead-magnet *) {
  background: #000;
  padding: 10px !important;
  white-space: pre-wrap !important;
  word-wrap: break-word;
}
.blog-editor-content .froala-code {
  font-size: 14px;
  color: #fff !important;
  display: block;
  background: #000;
  padding: 0px 10px !important;
  white-space: pre-wrap !important;
  margin-bottom: 13px;
  border-radius: 4px;
}

/* Global css starts */
.blog-editor-content html,
.blog-editor-content body,
.blog-editor-content div:not(.lead-magnet *),
.blog-editor-content span:not(.lead-magnet *),
.blog-editor-content applet,
.blog-editor-content object,
.blog-editor-content iframe,
.blog-editor-content h1:not(.lead-magnet *),
.blog-editor-content h2:not(.lead-magnet *),
.blog-editor-content h3:not(.lead-magnet *),
.blog-editor-content h4:not(.lead-magnet *),
.blog-editor-content h5:not(.lead-magnet *),
.blog-editor-content h6:not(.lead-magnet *),
.blog-editor-content p:not(.lead-magnet *),
.blog-editor-content blockquote:not(.lead-magnet *),
.blog-editor-content pre:not(.lead-magnet *),
.blog-editor-content a:not(.copy, .copied .lead-magnet *),
.blog-editor-content abbr,
.blog-editor-content acronym,
.blog-editor-content address,
.blog-editor-content big,
.blog-editor-content cite,
.blog-editor-content code,
.blog-editor-content del,
.blog-editor-content dfn,
.blog-editor-content em,
.blog-editor-content img:not(.lead-magnet *),
.blog-editor-content ins,
.blog-editor-content kbd,
.blog-editor-content q,
.blog-editor-content s,
.blog-editor-content samp,
.blog-editor-content small,
.blog-editor-content strike,
.blog-editor-content strong,
.blog-editor-content sub,
.blog-editor-content sup,
.blog-editor-content tt,
.blog-editor-content var,
.blog-editor-content b:not(.lead-magnet *),
.blog-editor-content u:not(.lead-magnet *),
.blog-editor-content i:not(.lead-magnet *),
.blog-editor-content center,
.blog-editor-content dl,
.blog-editor-content dt,
.blog-editor-content dd,
.blog-editor-content ol:not(.lead-magnet *),
.blog-editor-content ul:not(.lead-magnet *),
.blog-editor-content li:not(.lead-magnet *),
.blog-editor-content fieldset,
.blog-editor-content form:not(.lead-magnet *),
.blog-editor-content label:not(.lead-magnet *),
.blog-editor-content legend:not(.lead-magnet *),
.blog-editor-content table,
.blog-editor-content caption,
.blog-editor-content tbody,
.blog-editor-content tfoot,
.blog-editor-content thead,
.blog-editor-content tr,
.blog-editor-content th,
.blog-editor-content td,
.blog-editor-content article,
.blog-editor-content aside,
.blog-editor-content canvas,
.blog-editor-content details,
.blog-editor-content embed,
.blog-editor-content figure,
.blog-editor-content figcaption,
.blog-editor-content footer,
.blog-editor-content header,
.blog-editor-content hgroup,
.blog-editor-content menu,
.blog-editor-content nav,
.blog-editor-content output,
.blog-editor-content ruby,
.blog-editor-content section,
.blog-editor-content summary,
.blog-editor-content time,
.blog-editor-content mark,
.blog-editor-content audio,
.blog-editor-content video {
  margin: unset;
  padding: unset;
  border: unset;
  font-size: unset;
  font: unset;
}
.blog-editor-content ul li ul,
.blog-editor-content ul li ol,
.blog-editor-content ol li ul,
.blog-editor-content ol li ol {
  padding-left: 40px;
}
.blog-editor-content h1:not(.lead-magnet *) {
  margin-top: 21px;
  margin-bottom: 21px;
  line-height: 39px;
  font-size: 32px;
  color: #333333;
  font-weight: 700;
}
.blog-editor-content p:not(.lead-magnet *),
.blog-editor-content ol:not(.lead-magnet *),
.blog-editor-content ul:not(.lead-magnet *) {
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 28px;
  color: #444444;
  word-break: break-word;
}
.blog-editor-content ol li ol,
.blog-editor-content ul li ul,
.blog-editor-content ol li ul,
.blog-editor-content ul li ol {
  margin-top: 0;
  margin-bottom: 0;
}
.blog-editor-content ol:not(.lead-magnet *),
.blog-editor-content ul:not(.lead-magnet *) {
  padding-left: 40px;
  list-style-position: outside;
}
.blog-editor-content pre:not(.lead-magnet *) {
  margin-top: 13px;
  margin-bottom: 13px;
  line-height: 28px;
  color: #676767;
  background-color: rgba(31, 120, 209, 0.044);
  font-family: monospace;
  font-size: 16px;
}
.blog-editor-content h2:not(.lead-magnet *) {
  margin-top: 19px;
  margin-bottom: 19px;
  line-height: 38px;
  color: #333333;
  font-size: 24px;
  font-weight: 700;
}
.blog-editor-content h3:not(.lead-magnet *) {
  margin-top: 18px;
  margin-bottom: 18px;
  line-height: 23px;
  color: #333333;
  font-size: 18.7167px;
  font-weight: 700;
}
.blog-editor-content h4:not(.lead-magnet *) {
  margin-top: 21px;
  margin-bottom: 21px;
  line-height: 20px;
  color: #333333;
  font-size: 16px;
  font-weight: 700;
}
.blog-editor-content h5:not(.lead-magnet *) {
  margin-top: 22px;
  margin-bottom: 22px;
  line-height: 17px;
  font-size: 13.2833px;
  color: #333742;
  font-weight: 700;
}
.blog-editor-content h6:not(.lead-magnet *) {
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 14px;
  font-size: 10.7167px;
  color: #333742;
  font-weight: 700;
}
.blog-editor-content p:not(.lead-magnet *) {
  font-size: 1em;
}
.blog-editor-content blockquote {
  quotes: "\201C""\201D""\2018""\2019";
  font-size: 16px;
  border-radius: 0;
  border-left: 4px solid #333333;
  margin-left: 0px;
  padding-left: 5px;
  color: rgb(94, 53, 177);
  background-color: #f4f4f4;
  margin-top: 16px;
  margin-bottom: 16px;
}
/* .blog-editor-content blockquote::before{content:none;} */
.blog-editor-content a:not(.copy, .copied .lead-magnet *) {
  text-decoration: underline;
  color: #ff585c;
  line-height: 28px;
}
.dark .blog-editor-content blockquote {
  color: #ffffff !important;
}
.blog-editor-content blockquote p {
  color: #444444;
}
/* .blog-editor-content p img{width: 100% !important;} */
.blog-editor-content img:not(.lead-magnet *).fr-dib {
  width: 100% !important;
}
.blog-editor-content strong:not(.lead-magnet *) {
  color: #222222;
  font-weight: bold;
}
.blog-editor-content html,
.blog-editor-content body,
.blog-editor-content div:not(.lead-magnet *),
.blog-editor-content span:not(.lead-magnet *),
.blog-editor-content applet,
.blog-editor-content object,
.blog-editor-content iframe,
.blog-editor-content h1:not(.lead-magnet *),
.blog-editor-content h2:not(.lead-magnet *),
.blog-editor-content h3:not(.lead-magnet *),
.blog-editor-content h4:not(.lead-magnet *),
.blog-editor-content h5:not(.lead-magnet *),
.blog-editor-content h6:not(.lead-magnet *),
.blog-editor-content p:not(.lead-magnet *),
.blog-editor-content blockquote:not(.lead-magnet *),
.blog-editor-content pre:not(.lead-magnet *),
.blog-editor-content a:not(.copy, .copied .lead-magnet *),
.blog-editor-content abbr,
.blog-editor-content acronym,
.blog-editor-content address,
.blog-editor-content big,
.blog-editor-content cite,
.blog-editor-content code,
.blog-editor-content del,
.blog-editor-content dfn,
.blog-editor-content em,
.blog-editor-content img:not(.lead-magnet *),
.blog-editor-content ins,
.blog-editor-content kbd,
.blog-editor-content q,
.blog-editor-content s,
.blog-editor-content samp,
.blog-editor-content small,
.blog-editor-content strike,
.blog-editor-content strong,
.blog-editor-content sub,
.blog-editor-content sup,
.blog-editor-content tt,
.blog-editor-content var,
.blog-editor-content b:not(.lead-magnet *),
.blog-editor-content u:not(.lead-magnet *),
.blog-editor-content i:not(.lead-magnet *),
.blog-editor-content center,
.blog-editor-content dl,
.blog-editor-content dt,
.blog-editor-content dd,
.blog-editor-content ol:not(.lead-magnet *),
.blog-editor-content ul:not(.lead-magnet *),
.blog-editor-content li:not(.lead-magnet *),
.blog-editor-content fieldset,
.blog-editor-content form,
.blog-editor-content label:not(.lead-magnet *),
.blog-editor-content legend,
.blog-editor-content table,
.blog-editor-content caption,
.blog-editor-content tbody,
.blog-editor-content tfoot,
.blog-editor-content thead,
.blog-editor-content tr,
.blog-editor-content th,
.blog-editor-content td,
.blog-editor-content article,
.blog-editor-content aside,
.blog-editor-content canvas,
.blog-editor-content details,
.blog-editor-content embed,
.blog-editor-content figure,
.blog-editor-content figcaption,
.blog-editor-content footer,
.blog-editor-content header,
.blog-editor-content hgroup,
.blog-editor-content menu,
.blog-editor-content nav,
.blog-editor-content output,
.blog-editor-content ruby,
.blog-editor-content section,
.blog-editor-content summary,
.blog-editor-content time,
.blog-editor-content mark,
.blog-editor-content audio,
.blog-editor-content video {
  margin: unset;
  padding: unset;
  border: unset;
  font-size: unset;
  font: unset;
}
.blog-editor-content ul li ul,
.blog-editor-content ul li ol,
.blog-editor-content ol li ul,
.blog-editor-content ol li ol {
  padding-left: 40px;
}
.blog-editor-content ol li ol,
.blog-editor-content ul li ul,
.blog-editor-content ol li ul,
.blog-editor-content ul li ol {
  margin-top: 5px;
  margin-bottom: 0;
}
.blog-editor-content ol:not(.lead-magnet *),
.blog-editor-content ul:not(.lead-magnet *) {
  padding-left: 40px;
  list-style-position: outside;
  margin-bottom: 32px;
}
.blog-editor-content table ol,
.blog-editor-content table ul {
  padding-left: 10px !important;
}
.blog-editor-content pre:not(.lead-magnet *) {
  margin-top: 13px;
  margin-bottom: 13px;
  line-height: 28px;
  background: #000;
  color: #fff;
  padding: 0px 10px !important;
  white-space: pre-wrap !important;
  font-family: monospace;
  font-size: 16px;
}
.blog-editor-content p:not(.lead-magnet *) b {
  font-size: 20px;
  font-weight: 700;
  color: #231f20;
  line-height: normal;
}
.blog-editor-content span:not(.lead-magnet *).note {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #000000;
}
.blog-editor-content span:not(.lead-magnet *).note b {
  font-weight: 700;
  font-size: 12px;
}
.blog-editor-content blockquote {
  font-size: 16px;
  border-radius: 0;
  border-left: 4px solid #000;
  margin-left: 0px;
  padding: 12px;
  color: rgb(94, 53, 177);
  background-color: #f4f4f4;
  margin-top: 16px;
  margin-bottom: 16px;
}
.blog-editor-content blockquote::before {
  content: none;
}
.blog-editor-content a:not(.copy, .copied .lead-magnet *) {
  text-decoration: underline;
  color: #586feb !important;
  line-height: 28px;
  font-size: 16px;
  display: inline-block;
}
/* .blog-editor-content a:not(.copy, .copied .lead-magnet *):hover {
  color: #586feb;
  background-color: #fafafa;
} */
.blog-editor-content blockquote p {
  color: #231f20;
  line-height: 33px;
}
.blog-editor-content
  img:not(.lead-magnet *):not(.code-before img, .code-after img) {
  width: 100% !important;
  height: 100% !important;
}
.blog-editor-content img:not(.lead-magnet *).fr-dib {
  width: 100% !important;
  display: block;
}
.blog-editor-content strong:not(.lead-magnet *) {
  color: #222222;
  font-weight: 700 !important;
}
.blog-editor-content pre strong {
  color: #fff !important;
}
.blog-editor-content em {
  font-style: italic !important;
}
table {
  z-index: 1 !important;
  position: relative;
}
.blog-editor-content table {
  margin-top: 10px;
  margin-bottom: 20px;
  height: auto !important;
  z-index: 1;
  position: relative;
  width: 100% !important;
}
.blog-editor-content th {
  line-height: normal;
  font-weight: 600;
  background: #f8f8f8 !important;
  color: #182d4d;
  padding: 10px;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-color: #eee !important;
}
.blog-editor-content td {
  padding: 10px;
  font-weight: 400 !important;
  line-height: 22px;
}
.blog-editor-content td strong {
  font-weight: 700 !important;
}
.blog-editor-content table.table-two > tbody > tr:nth-child(2) {
  border-bottom: 1px solid #eee;
}
.blog-editor-content table > tbody > tr > td {
  border: 1px solid #eee;
  padding: 10px 16px;
  line-height: 22px;
}
.blog-editor-content table td p {
  font-size: 13px !important;
}
.blog-editor-content table > tbody > tr > td img.fr-dib {
  width: 70% !important;
  display: block;
}
.blog-editor-content table > tbody > tr > td a {
  word-wrap: break-word;
  display: block;
  line-height: 18px;
  font-size: 12px;
}
.blog-editor-content table.table-striped > tbody > tr:nth-of-type(2n + 1),
.blog-editor-content table > tbody > tr:nth-of-type(2n + 1) {
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    #f8f8f8 30%,
    #f8f8f8 52%,
    #f8f8f8 71%,
    #ffffff 100%
  );
}
.blog-editor-content
  a:not(.copy, .copied, .lead-magnet *):not(.code-before, .code-after) {
  text-decoration: underline;
  color: #586feb !important;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  display: inline;
}
.blog-editor-content
  a:not(.copy, .copied, .lead-magnet *):not(.code-before, .code-after):hover {
  color: #000 !important;
}
.inline-links a {
  background-color: #fff;
  display: block;
  margin-bottom: 10px;
  line-height: 13px;
  border: 1px solid #999;
  box-shadow: none;
  border-radius: 21px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  text-transform: capitalize;
  padding: 12px 16px;
  transition-duration: 0.3s;
}
.inline-links a:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
.blog-header-arrow-down-icon {
  background: url("https://d2o2utebsixu4k.cloudfront.net/assets/images/icons/header_sprite.png");
  background-repeat: no-repeat;
  width: 6px;
  height: 4px;
  background-position: -449px -196px;
}

.blog-icons {
  background: url("https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg");
  background-repeat: no-repeat;
}

.blog-header-call-icon {
  background-position: -778px -467px;
  width: 14px;
  height: 14px;
}

.icon-eye {
  width: 16px;
  height: 12px;
  background-position: -992px -6px;
}
.icon-right-arrow-small {
  background-position: -163px -8px;
  width: 6px;
  height: 9px;
}
.icon-right-arrow-large {
  background-position: -388px -6px;
  width: 7px;
  height: 12px;
}
.icon-pc {
  background-position: -305px -3px;
  width: 18px;
  height: 18px;
}
.icon-cart {
  background-position: -756px -1px;
  width: 23px;
  height: 22px;
}
.icon-search-gray {
  background-position: -7px -5px;
  width: 15px;
  height: 15px;
}
.icon-search-black {
  background-position: -841px -4px;
  width: 16px;
  height: 16px;
}
.icon-search-black-large {
  background-position: -441px -355px;
  width: 21px;
  height: 21px;
}
.icon-share-small {
  background-position: -84px -5px;
  width: 15px;
  height: 15px;
}
.icon-share-large {
  background-position: -691px -354px;
  width: 24px;
  height: 24px;
}
.icon-down-arrow-black {
  background-position: -232px -8px;
  width: 12px;
  height: 8px;
}
@media screen and (max-width: 576px) {
  .icon-down-arrow-black-small {
    background-position: -230px -2px !important;
    width: 16px;
    height: 16px;
    transform: rotate(0deg) !important;
  }
  .icon-up-arrow-black-small {
    background-position: -232px -6px;
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
}
.icon-down-arrow-black {
  background-position: -232px -6px;
  width: 16px;
  height: 16px;
}
.icon-down-arrow-white {
  background-position: -522px -127px;
  width: 14px;
  height: 9px;
}
.icon-home-black {
  background-position: -460px -2px;
  width: 20px;
  height: 20px;
}
.icon-home-gray {
  background-position: -610px -356px;
  width: 17px;
  height: 17px;
}
.icon-like-gray {
  background-position: -956px -230px;
  width: 20px;
  height: 19px;
}
.icon-like-blue {
  background-position: -1118px -230px;
  width: 20px;
  height: 19px;
}
.icon-reply {
  background-position: -1037px -232px;
  width: 20px;
  height: 15px;
}
.icon-close-download-lead-form {
  background-position: -946px -359px;
  width: 10px;
  height: 10px;
}
.icon-close {
  background-position: -945px -358px;
  width: 12px;
  height: 12px;
}
.icon-close-white {
  background-position: -19px -550px;
  width: 14px;
  height: 14px;
}
.icon-video-play {
  background-position: -613px -550px;
  width: 21px;
  height: 20px;
}
.icon-video-1 {
  background-position: -106px -556px;
  width: 29px;
  height: 26px;
}
.icon-video-2 {
  background-position: -214px -555px;
  width: 26px;
  height: 27px;
}
.icon-video-3 {
  background-position: -323px -554px;
  width: 28px;
  height: 28px;
}
.icon-video-4 {
  background-position: -424px -554px;
  width: 33px;
  height: 26px;
}
.video-thumb-links li a:before {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg");
  width: 6px;
  background-position: -532px -553px;
  height: 10px;
}
.bg-button-gradient {
  background: linear-gradient(
    94.6deg,
    #b731bc 0%,
    #d65774 48.91%,
    #ec7642 100%
  );
  border-radius: 3px;
}

.icon-hamburg {
  background-position: -527px -360px;
  width: 18px;
  height: 12px;
}
.icon-maestro {
  background-position: -495px -61px;
  width: 39px;
  height: 24px;
}
.icon-american-express {
  background-position: -594px -61px;
  width: 39px;
  height: 24px;
}
.icon-paypal {
  background-position: -693px -61px;
  width: 39px;
  height: 24px;
}
.icon-visa {
  background-position: -792px -61px;
  width: 39px;
  height: 24px;
}
.icon-master-card {
  background-position: -891px -61px;
  width: 39px;
  height: 24px;
}
.icon-citrus {
  background-position: -990px -61px;
  width: 39px;
  height: 24px;
}
.icon-after-pay {
  background-position: -1089px -61px;
  width: 69px;
  height: 24px;
}
.icon-usa {
  background-position: -1218px -66px;
  width: 27px;
  height: 15px;
}
.icon-uk {
  background-position: -1305px -66px;
  width: 27px;
  height: 15px;
}
.icon-india {
  background-position: 0 -124px;
  width: 27px;
  height: 15px;
}
.icon-singapore {
  background-position: -87px -124px;
  width: 27px;
  height: 15px;
}
.icon-canada {
  background-position: -174px -124px;
  width: 27px;
  height: 15px;
}
.icon-new-zealand {
  background-position: -261px -124px;
  width: 27px;
  height: 15px;
}
.icon-australia {
  background-position: -348px -124px;
  width: 27px;
  height: 15px;
}
.icon-uae {
  background-position: -435px -124px;
  width: 27px;
  height: 15px;
}
.icon-malaysia {
  background-position: -596px -124px;
  width: 27px;
  height: 15px;
}
.icon-ireland {
  background-position: -683px -124px;
  width: 27px;
  height: 15px;
}
.icon-facebook-small {
  background-position: -1072px -5px;
  width: 8px;
  height: 14px;
}
.icon-twitter-small {
  background-position: -1145px -6px;
  width: 14px;
  height: 11px;
}
.icon-linkedin-small {
  background-position: -1221px -5px;
  width: 14px;
  height: 14px;
}
.icon-whatsapp {
  background-position: -1297px -5px;
  width: 14px;
  height: 14px;
}
.icon-linkedin-large {
  background-position: -5px -63px;
  width: 18px;
  height: 20px;
}
.icon-facebook-large {
  background-position: -89px -63px;
  width: 13px;
  height: 20px;
}
.icon-rss-large {
  background-position: -694px -462px;
  width: 18px;
  height: 18px;
}
.icon-instagram {
  background-position: -168px -63px;
  width: 20px;
  height: 20px;
}
.icon-youtube {
  background-position: -248px -63px;
  width: 24px;
  height: 21px;
}
.icon-twitter-large {
  background-position: -332px -64px;
  width: 21px;
  height: 17px;
}
.icon-pinterest {
  background-position: -414px -62px;
  width: 19px;
  height: 23px;
}
.icon-blogs-large {
  background-position: -7px -212px;
  width: 66px;
  height: 54px;
}
.icon-infographics-large {
  background-position: -145px -211px;
  width: 70px;
  height: 57px;
}
.icon-news-large {
  background-position: -287px -211px;
  width: 67px;
  height: 56px;
}
.icon-announcements-large {
  background-position: -427px -212px;
  width: 67px;
  height: 55px;
}
.icon-blogs-small {
  background-position: -4px -349px;
  width: 42px;
  height: 34px;
}
.icon-infographics-small {
  background-position: -113px -348px;
  width: 44px;
  height: 36px;
}
.icon-news-small {
  background-position: -224px -348px;
  width: 43px;
  height: 36px;
}
.icon-announcements-small {
  background-position: -334px -349px;
  width: 43px;
  height: 35px;
}
.icon-linkedin-colored {
  background-position: -560px -227px;
  width: 24px;
  height: 24px;
}
.icon-facebook-colored {
  background-position: -655px -227px;
  width: 24px;
  height: 24px;
}
.icon-twitter-colored {
  background-position: -750px -227px;
  width: 24px;
  height: 24px;
}
.icon-youtube-colored {
  background-position: -845px -227px;
  width: 34px;
  height: 24px;
}
.icon-whatsapp-colored {
  background-position: -338px -457px;
  width: 24px;
  height: 24px;
}

.icon-facebook-side {
  background-position: -957px -451px;
  width: 24px;
  height: 24px;
}
.icon-twitter-side {
  background-position: -862px -451px;
  width: 24px;
  height: 24px;
}
.icon-linkedin-side {
  background-position: -1147px -451px;
  width: 24px;
  height: 24px;
}
.icon-whatsapp-side {
  background-position: -1052px -451px;
  width: 24px;
  height: 24px;
}
.icon-down-arrow-blue {
  background-position: -542px -8px;
  width: 14px;
  height: 8px;
}
.icon-up-arrow-blue {
  background-position: -616px -8px;
  width: 14px;
  height: 8px;
}
@media screen and (min-width: 576px) {
  .icon-up-arrow-blue-large {
    background-position: -616px -8px;
    width: 14px;
    height: 8px;
  }
}
.icon-right-arrow-pink {
  background-position: -689px -6px;
  width: 8px;
  height: 13px;
}
.icon-right-arrow-green {
  background-position: -922px -6px;
  width: 7px;
  height: 12px;
}
.icon-down-arrow-outlined {
  background-position: -859px -356px;
  width: 20px;
  height: 20px;
}
.icon-up-arrow-outlined {
  background-position: -775px -356px;
  width: 20px;
  height: 20px;
}
/* mega menu */
.mega-menu-height {
  height: 470px;
}
/* scroll bar styling for mega menu */
.customized-scrollbar {
  overflow-y: auto;
  scrollbar-color: #ddd #fff;
  scrollbar-width: thin;
}
.customized-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.customized-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(209 213 219);
}
.customized-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.customized-hover-scrollbar {
  overflow-y: hidden;
  scrollbar-color: #ddd #fff;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}
.customized-hover-scrollbar:hover {
  overflow-y: auto;
}
.customized-hover-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.customized-hover-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(209 213 219);
}
.customized-hover-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.dropdown-menu {
  left: auto;
  right: 9%;
}

@media screen and (max-width: 1260px) {
  .dropdown-menu {
    right: 0% !important;
  }
}

.blog-editor-content h1:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 38px;
  line-height: 57px;
  font-weight: 700;
  color: #231f20;
  letter-spacing: -1px;
}
.blog-editor-content h2:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  color: #231f20;
  letter-spacing: normal;
}
.blog-editor-content h3:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #231f20;
  letter-spacing: normal;
}
.blog-editor-content h4:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  color: #231f20;
  letter-spacing: normal;
}
.blog-editor-content h5:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #231f20;
  letter-spacing: -0.5px;
}
.blog-editor-content h6:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #231f20;
  letter-spacing: -0.5px;
}
.blog-editor-content p:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 26px;
  color: #231f20;
  letter-spacing: 0.2px;
}
.blog-editor-content li:not(.lead-magnet *) {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 26px !important;
  color: #000000;
  padding-left: 0px;
}
.blog-editor-content td,
.blog-editor-content th {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  padding: 10px 16px !important;
}
.blog-editor-content ul ul ul {
  list-style-type: square;
}
.blog-editor-content ul ul,
.blog-editor-content ol ul {
  list-style-type: circle;
}
.blog-editor-content table {
  margin-top: 25px;
  margin-bottom: 25px;
}
.blog-editor-content th {
  line-height: normal;
  font-weight: 600;
  background: #f8f8f8 !important;
  color: #182d4d;
  padding: 10px;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-color: #eee !important;
}
.blog-editor-content td {
  padding: 10px;
  font-weight: 400 !important;
  line-height: 22px;
}
.blog-editor-content td strong {
  font-weight: 700 !important;
}
.blog-editor-content table.table-two > tbody > tr:nth-child(2) {
  border-bottom: 1px solid #eee;
}
.blog-editor-content table > tbody > tr > td {
  border: 1px solid #eee;
  padding: 10px 16px;
  line-height: 22px;
}
.blog-editor-content table td p {
  font-size: 13px !important;
}
.blog-editor-content table > tbody > tr > td img.fr-dib {
  width: 70% !important;
  display: block;
}
.blog-editor-content table > tbody > tr > td a {
  word-wrap: break-word;
  display: block;
  line-height: 18px;
  font-size: 13px !important;
}
.blog-editor-content table.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    #f8f8f8 30%,
    #f8f8f8 52%,
    #f8f8f8 71%,
    #ffffff 100%
  );
}
.blog-editor-content td,
.blog-editor-content th {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  padding: 10px !important;
}

.blog-editor-content table > tbody > tr:nth-of-type(2n + 1) {
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    #f8f8f8 30%,
    #f8f8f8 52%,
    #f8f8f8 71%,
    #ffffff 100%
  );
}

.blog-editor-content ul:not(.lead-magnet *) {
  list-style: disc;
}
.blog-editor-content ol:not(.lead-magnet *) {
  list-style: decimal;
}
.blog-editor-content ol li ol li {
  list-style-type: lower-alpha;
}
.blog-editor-content table ul,
.blog-editor-content table ol {
  margin-top: 17px;
}
.blog-editor-content table ul li,
.blog-editor-content table ol li {
  font-size: 12px !important;
  line-height: 22px !important;
  font-weight: 400;
  color: #231f20;
}
.blog-editor-content div:not(.lead-magnet *) {
  line-height: 27px !important;
}

.author-bio p {
  margin-bottom: 10px;
}
.snippet-code {
  margin-bottom: 25px !important;
}
.snippet-code a {
  float: right;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  border: 1px solid transparent !important;
  padding: 6px 12px 6px 32px !important;
  border-radius: 17px;
  text-decoration: none !important;
  line-height: normal !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: pointer;
}
.snippet-code a.copy {
  border-color: #586feb !important;
  color: #586feb !important;
}

.snippet-code a.copied {
  color: #000000 !important;
}

.snippet-code a:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg");
  width: 16px;
}
.snippet-code a.copy:before {
  background-position: -509px -465px;
  height: 9px;
}
.snippet-code a.copied:before {
  background-position: -433px -461px;
  height: 16px;
}

.useful-link-list a {
  @apply mr-3 mb-3 border rounded-3xl border-gray-500 text-gray-500 font-semibold capitalize text-xs md:leading-3 xs:leading-4  md:py-3 md:px-4 xs:px-3 xs:py-2 hover:bg-black hover:text-white block;
}

@media only screen and (max-width: 991px) {
  .blog-editor-content table,
  .blog-editor-content table,
  .blog-editor-content table {
    display: block;
    white-space: nowrap;
    width: 80vw;
    overflow-x: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .blog-editor-content iframe,
  .blog-editor-content video {
    width: 100% !important;
  }
  .fr-embedly {
    height: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .blog-editor-content table {
    border: 1px solid #eee !important;
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  .snippet-code a {
    border: none !important;
    position: relative;
    float: none;
    width: 100%;
    background-color: #586feb !important;
    color: #fff !important;
    padding: 10px 12px !important;
    border-radius: 0;
    text-decoration: none !important;
    line-height: normal !important;
    text-align: center;
    color: #ffffff !important;
  }
  .snippet-code a.copy {
    color: #ffffff !important;
    display: inline-block;
  }
  .snippet-code a.copied {
    color: #ffffff !important;
    background: #000000 !important;
    display: inline-block;
  }
  .snippet-code a.copy::before {
    background-position: -585px -465px;
  }
  .snippet-code a.copy:before {
    left: 35%;
    top: 51%;
  }
  .snippet-code a.copied:before {
    left: 33%;
  }

  .blog-editor-content li:not(.lead-magnet *) {
    font-size: 13px;
  }
  .blog-editor-content pre:not(.lead-magnet *) {
    border-radius: 0 !important;
    margin-bottom: 0;
    padding: 12px !important;
    white-space: normal !important;
    letter-spacing: -0.5px;
    background-color: #231f20;
  }
  .blog-editor-content p:not(.lead-magnet *) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    letter-spacing: 0.1px;
  }
  .blog-editor-content h2.toc_header:not(.lead-magnet *) {
    padding-top: 50px;
  }
  .inline-links a {
    padding: 10px 12px;
  }

  #frequently-asked-questions.toc_header {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .blog-editor-content p:not(.lead-magnet *) {
    color: #000;
  }
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-element .fr-embedly {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: relative;
}
.fr-element .fr-embedly::after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: block;
  background: rgba(0, 0, 0, 0);
}
.fr-element .fr-embedly > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  max-width: 100%;
  border: 0;
}
.fr-box .fr-embedly-resizer {
  position: absolute;
  border: solid 1px #1e88e5;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-box .fr-embedly-resizer.fr-active {
  display: block;
}

img.fr-rounded,
.fr-img-caption.fr-rounded img {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
img.fr-bordered,
.fr-img-caption.fr-bordered img {
  border: solid 5px #ccc;
}
img.fr-bordered {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fr-img-caption.fr-bordered img {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img.fr-shadow,
.fr-img-caption.fr-shadow img {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.blog-editor-content span[style~="color:"] a {
  color: inherit;
}
.blog-editor-content strong:not(.lead-magnet *) {
  font-weight: 700;
}
.blog-editor-content table {
  border: none;
  border-collapse: collapse;
  empty-cells: show;
  max-width: 100%;
}
.blog-editor-content table.fr-dashed-borders td,
.blog-editor-content table.fr-dashed-borders th {
  border-style: dashed;
}
.blog-editor-content table.fr-alternate-rows tbody tr:nth-child(2n) {
  background: #f5f5f5;
}
.blog-editor-content table td,
.blog-editor-content table th {
  border: 1px solid #dddddd;
}
.blog-editor-content table td:empty,
.blog-editor-content table th:empty {
  height: 20px;
}
.blog-editor-content table td.fr-highlighted,
.blog-editor-content table th.fr-highlighted {
  border: 1px double red;
}
.blog-editor-content table td.fr-thick,
.blog-editor-content table th.fr-thick {
  border-width: 2px;
}
.blog-editor-content table th {
  background: #e6e6e6;
}
.blog-editor-content hr:not(.lead-magnet *) {
  clear: both;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  page-break-after: always;
}
.blog-editor-content .fr-file {
  position: relative;
}
.blog-editor-content .fr-file::after {
  position: relative;
  content: "\1F4CE";
  font-weight: normal;
}
.blog-editor-content pre:not(.lead-magnet *) {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.blog-editor-content[dir="rtl"] blockquote {
  border-left: none;
  border-right: solid 2px #5e35b1;
  margin-right: 0;
  padding-right: 5px;
  padding-left: 0px;
}
.blog-editor-content[dir="rtl"] blockquote blockquote {
  border-color: #00bcd4;
}
.blog-editor-content[dir="rtl"] blockquote blockquote blockquote {
  border-color: #43a047;
}
.blog-editor-content blockquote blockquote {
  border-color: #00bcd4;
  color: #00bcd4;
}
.blog-editor-content blockquote blockquote blockquote {
  border-color: #43a047;
  color: #43a047;
}
.blog-editor-content span:not(.lead-magnet *).fr-emoticon {
  font-weight: normal;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji",
    "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
  display: inline;
  line-height: 0;
}
.blog-editor-content span:not(.lead-magnet *).fr-emoticon.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}
.blog-editor-content .fr-text-gray {
  color: #aaa !important;
}
.blog-editor-content .fr-text-bordered {
  border-top: solid 1px #222;
  border-bottom: solid 1px #222;
  padding: 10px 0;
}
.blog-editor-content .fr-text-spaced {
  letter-spacing: 1px;
}
.blog-editor-content .fr-text-uppercase {
  text-transform: uppercase;
}
.blog-editor-content img:not(.lead-magnet *) {
  position: relative;
  max-width: 100%;
}
.blog-editor-content img:not(.lead-magnet *).fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;
}
.blog-editor-content img:not(.lead-magnet *).fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.blog-editor-content img:not(.lead-magnet *).fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.blog-editor-content img:not(.lead-magnet *).fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.blog-editor-content img:not(.lead-magnet *).fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.blog-editor-content img:not(.lead-magnet *).fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption {
  position: relative;
  max-width: 100%;
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.blog-editor-content span:not(.lead-magnet *).fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.blog-editor-content .fr-video {
  text-align: center;
  position: relative;
}
.blog-editor-content .fr-video > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  max-width: 100%;
  border: none;
}
.blog-editor-content .fr-video.fr-dvb {
  display: block;
  clear: both;
}
.blog-editor-content .fr-video.fr-dvb.fr-fvl {
  text-align: left;
}
.blog-editor-content .fr-video.fr-dvb.fr-fvr {
  text-align: right;
}
.blog-editor-content .fr-video.fr-dvi {
  display: inline-block;
}
.blog-editor-content .fr-video.fr-dvi.fr-fvl {
  float: left;
}
.blog-editor-content .fr-video.fr-dvi.fr-fvr {
  float: right;
}
.blog-editor-content a:not(.copy, .copied .lead-magnet *).fr-strong {
  font-weight: 700;
}
.blog-editor-content a:not(.copy, .copied .lead-magnet *).fr-green {
  color: green;
}
.blog-editor-content .fr-img-caption {
  text-align: center;
}
.blog-editor-content .fr-img-caption .fr-img-wrap {
  padding: 0px;
  display: inline-block;
  margin: auto;
  text-align: center;
  width: 100%;
}
.blog-editor-content .fr-img-caption .fr-img-wrap img {
  display: block;
  margin: auto;
  width: 100%;
}
.blog-editor-content .fr-img-caption .fr-img-wrap > span {
  margin: auto;
  display: block;
  padding: 5px 5px 10px;
  font-size: 14px;
  font-weight: initial;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  width: 100%;
  text-align: center;
}
.blog-editor-content button:not(.lead-magnet *).fr-rounded,
.blog-editor-content input:not(.lead-magnet *).fr-rounded,
.blog-editor-content textarea:not(.lead-magnet *).fr-rounded {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.blog-editor-content button:not(.lead-magnet *).fr-large,
.blog-editor-content input:not(.lead-magnet *).fr-large,
.blog-editor-content textarea:not(.lead-magnet *).fr-large {
  font-size: 24px;
}
/**
 * Image style.
 */
a.fr-view.fr-strong {
  font-weight: 700;
}
a.fr-view.fr-green {
  color: green;
}
/**
 * Link style.
 */
img.fr-view {
  position: relative;
  max-width: 100%;
}
img.fr-view.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;
}
img.fr-view.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
img.fr-view.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
img.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
img.fr-view.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
img.fr-view.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
span.fr-img-caption.fr-view {
  position: relative;
  max-width: 100%;
}
span.fr-img-caption.fr-view.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;
}
span.fr-img-caption.fr-view.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
span.fr-img-caption.fr-view.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
span.fr-img-caption.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
span.fr-img-caption.fr-view.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
span.fr-img-caption.fr-view.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

/*Blog View*/
.dark .fr-view {
  color: #fff !important;
}
.blog-editor-content em {
  font-style: italic;
}

.blog-editor-content ul:not(.lead-magnet *) {
  list-style-type: disc;
}
.blog-editor-content ol:not(.lead-magnet *) {
  list-style-type: decimal;
}
.blog-editor-content ul ul,
.blog-editor-content ol ul {
  list-style-type: circle;
}
.blog-editor-content ol ol {
  list-style-type: decimal;
}
.blog-editor-content ul ol {
  list-style-type: lower-latin;
}
.blog-editor-content ul ul ul {
  list-style-type: square;
}
.blog-editor-content p:not(.lead-magnet *),
.blog-editor-content ol,
.blog-editor-content ul {
  word-wrap: break-word;
}
.blog-editor-content .embdscl0 {
  display: none !important;
}

.blog-editor-content code {
  color: #c7254e;
  background-color: #f9f2f4;
}

.faq .blog-editor-content p:not(.lead-magnet *):last-child {
  margin-bottom: 0;
}
.faq .blog-editor-content ul:last-child {
  margin-bottom: 0;
}

.blog-accredition-icons {
  background: url("https://d2o2utebsixu4k.cloudfront.net/accrediations.png");
  background-repeat: no-repeat;
}

.acdtn-page-1 {
  background-position: -46px -3px;
  width: 34px;
  height: 35px;
}
.acdtn-page-2 {
  background-position: -213px -8px;
  width: 91px;
  height: 24px;
}
.acdtn-page-3 {
  background-position: -404px 0;
  width: 97px;
  height: 40px;
}
.acdtn-page-4 {
  background-position: -601px -8px;
  width: 90px;
  height: 24px;
}
.acdtn-page-5 {
  background-position: -779px -7px;
  width: 92px;
  height: 26px;
}
.acdtn-page-6 {
  background-position: -943px -12px;
  width: 91px;
  height: 16px;
}
.acdtn-page-7 {
  background-position: -1117px -1px;
  width: 36px;
  height: 38px;
}
.acdtn-page-8 {
  background-position: -1252px -4px;
  width: 91px;
  height: 33px;
}
.acdtn-page-9 {
  background-position: -31px -105px;
  width: 63px;
  height: 53px;
}
.acdtn-page-10 {
  background-position: -213px -126px;
  width: 91px;
  height: 10px;
}
.acdtn-page-11 {
  background-position: -406px -117px;
  width: 92px;
  height: 28px;
}
.acdtn-page-12 {
  background-position: -406px -117px;
  width: 92px;
  height: 28px;
}
.acdtn-page-13 {
  background-position: -797px -104px;
  width: 56px;
  height: 54px;
}
.acdtn-page-14 {
  background-position: -942px -111px;
  width: 93px;
  height: 41px;
}
.acdtn-page-15 {
  background-position: -1116px -112px;
  width: 39px;
  height: 39px;
}
.acdtn-page-16 {
  background-position: -1250px -115px;
  width: 96px;
  height: 32px;
}
.acdtn-page-17 {
  background-position: -46px -228px;
  width: 34px;
  height: 43px;
}
.acdtn-page-18 {
  background-position: -211px -225px;
  width: 95px;
  height: 48px;
}
.acdtn-page-19 {
  background-position: -407px -236px;
  width: 90px;
  height: 26px;
}
.acdtn-page-20 {
  background-position: -583px -222px;
  width: 125px;
  height: 54px;
}
.acdtn-page-21 {
  background-position: -803px -223px;
  width: 44px;
  height: 53px;
}
.acdtn-page-22 {
  background-position: -963px -224px;
  width: 51px;
  height: 50px;
}
.acdtn-page-23 {
  background-position: -1106px -224px;
  width: 58px;
  height: 51px;
}
.acdtn-page-24 {
  background-position: -1235px -224px;
  width: 125px;
  height: 51px;
}
.acdtn-page-25 {
  background-position: 0 -342px;
  width: 125px;
  height: 49px;
}
.acdtn-page-26 {
  background-position: -196px -343px;
  width: 125px;
  height: 48px;
}
.acdtn-page-27 {
  background-position: -392px -340px;
  width: 120px;
  height: 54px;
}
.acdtn-page-28 {
  background-position: -615px -340px;
  width: 62px;
  height: 54px;
}
.acdtn-page-29 {
  background-position: -799px -341px;
  width: 52px;
  height: 51px;
}
.acdtn-page-30 {
  background-position: -963px -341px;
  width: 52px;
  height: 52px;
}
.acdtn-page-31 {
  background-position: -1100px -351px;
  width: 70px;
  height: 32px;
}

.acdtn-page-66 {
  background-position: -815px -698px;
  width: 38px;
	height: 38px;
}

.icon-tick-outlined {
  background-position: -179px -459px;
  width: 20px;
  height: 20px;
}
.icon-course-book {
  background-position: -1019px -355px;
  width: 24px;
  height: 19px;
}
.icon-tick {
  background-position: -259px -465px;
  width: 15px;
  height: 12px;
}

.icon-right-arrow-white-small {
  background-position: -1180px -359px;
  width: 8px;
  height: 12px;
}
.icon-left-arrow-white-small {
  background-position: -1106px -359px;
  width: 8px;
  height: 12px;
}
.icon-right-arrow-white-large {
  background-position: -101px -459px;
  width: 14px;
  height: 22px;
}
.icon-left-arrow-white-large {
  background-position: -17px -459px;
  width: 14px;
  height: 22px;
}
.blog-editor-content sup {
  top: -0.3em;
}
.next-image-styles span {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}

.next-image-styles span img {
  position: static !important;
}
.next-banner-image-styles span {
  display: block !important;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.gradient-button {
  background: linear-gradient(165.62deg, #ff702a -104.55%, #c507c9 216.3%);
  border-radius: 4px;
}

.gradient-button-2 {
  background: linear-gradient(156.92deg, #FF702A -94.42%, #C507C9 253.96%);
}

.gradient-button-3 {
  background: linear-gradient(156.71deg, #FF702A -93.73%, #C507C9 253.6%);
}

.gradient-button-4 {
  background: linear-gradient(153.43deg, #ff702a -93.5%, #c507c9 252.13%);
}

.gradient-button-5 {
  background: linear-gradient(153.78deg, #ff702a -94.47%, #c507c9 252.89%);
}

.gradient-button-6 {
  background: linear-gradient(130deg, #ff702a 0%, #c507c9 100%);
}

@media screen and (max-width: 768px) {
  .gradient-button-6 {
    background: linear-gradient(146deg, #ff702a 0%, #c507c9 100%);
  }
}

#tidio-chat-iframe {
  bottom: 60px !important;
}

.form-checkbox[type="checkbox"] + span::before {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  border: 1px solid gray;
  border-radius: 2px;
}

.form-checkbox[type="checkbox"]:checked + span::before {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  background: linear-gradient(150deg, #ff702a 0%, #c507c9 100%);
  border-radius: 2px;
  border: none;
}

.form-checkbox[type="checkbox"]:checked + span::after {
  content: "";
  position: absolute;
  background: url("/images/course-latest-sprite-1.png") no-repeat -9px -156px;
  width: 12px;
  height: 9px;
  transform: translate(45%, 80%);
  scale: 1.4;
  filter: brightness(10);
}

/* .form-checkbox-small {
  display: grid;
  place-content: center;
  border-radius: 10px;
  position: absolute !important;
  left: 0 !important;
} */

.form-checkbox-small[type="checkbox"] + span::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid gray;
  border-radius: 2px;
}

.form-checkbox-small[type="checkbox"]:checked + span::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: linear-gradient(150deg, #ff702a 0%, #c507c9 100%);
  border-radius: 2px;
  border: none;
}

.download-lf-cb.form-checkbox-small[type="checkbox"]:checked + span::before {
  background: #586feb;
}

.form-checkbox-small[type="checkbox"]:checked + span::after {
  content: "";
  position: absolute;
  background: url("https://d2o2utebsixu4k.cloudfront.net/blogs/sprites/blogs-icon.svg")
    no-repeat -259px -463px;
  width: 15px;
  height: 12px;
  scale: 0.8;
  filter: brightness(10);
  transform: translate(5%, 20%);
}

.text-10 {
  font-size: 10px;
}

.transform-translate-50 {
  transform: translateY(-50%);
}

@media screen and (max-width: 766px) {
  .transform-translate-50 {
    transform: none;
  }
}

.c-t-highlight {
  background-color: black;
  color: #fff !important;
  margin: 0 2px;
  display: inline-block;
  padding: 0 4px;
  font-size: 20px;
  line-height: 20px;
  transform: skew(-15deg);
}

.bg-pop-up-gradient {
  background: linear-gradient(
    90.79deg,
    #b731bc -7.12%,
    #d65774 49.7%,
    #ec7642 107.85%
  );
}

.exit-pop-up-gradient {
  background: linear-gradient(
    90.79deg,
    #b731bc 0%,
    #d65774 49.42%,
    #ec7642 100%
  );
}
@media screen and (max-width: 768px) {
  .exit-pop-up-gradient-mobile {
    background: linear-gradient(146deg, #ff702a 0%, #c507c9 100%);
  }
}

.exit-icon-close {
  background-position: -704px -553px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}
.exit-white-icon-close {
  background-position: -879px -552px;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
}

.exit-tick-icon {
  background-repeat: no-repeat;
  background-position: -791px -556px;
  width: 13px;
  height: 9px;
}
.exit-edit-icon {
  background-repeat: no-repeat;
  background-position: -124px -636px;
  width: 14px;
  height: 16px;
}

.exit-refer-and-earn-icon {
  background-position: -213px -642px;
  width: 8px;
  height: 8px;
}

.exit-linkedIn-icon {
  background-position: -295px -638px;
  width: 32px;
  height: 32px;
}

.exit-twitter-icon {
  background-position: -397px -638px;
  width: 32px;
  height: 32px;
}

.exit-instagram-icon {
  background-position: -499px -638px;
  width: 32px;
  height: 32px;
}
.exit-facebook-icon {
  background-position: -601px -638px;
  width: 32px;
  height: 32px;
}

.exit-linkedIn-small-icon {
  background-position: -789px -645px;
  width: 24px;
  height: 24px;
}

.exit-twitter-small-icon {
  background-position: -883px -645px;
  width: 24px;
  height: 24px;
}

.exit-instagram-small-icon {
  background-position: -977px -645px;
  width: 24px;
  height: 24px;
}

.exit-facebook-small-icon {
  background-position: -1071px -645px;
  width: 24px;
  height: 24px;
}

.free-skew {
  display: inline-block;
  transform: skew(-6deg);
  background-color: white;
  color: #000;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 700;
  font-style: italic;
  font-size: 16px;
  line-height: 21px;
  margin: 0 6px;
}

@media screen and (max-width: 766px) {
  .free-skew {
    color: #000000;
    background-color: transparent;
    transform: skew(0);
    padding-left: 0px;
    padding-right: 0px;
    font-style: normal;
    font-size: 14px;
  }
}

.left-toc-sticky {
  width: 200px !important;
  margin-left: -36px;
}

.left-side-toc {
  padding: 10px 5px 6px;
  border-radius: 4px;
}
.left-side-toc ol {
  list-style: none;
}
.left-side-toc ol li {
  padding-left: 23px;
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 16px;
  font-size: 12px;
}
.left-side-toc ol li::before {
  content: counter(list-item) ".";
  position: absolute;
  left: 5px;
  top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  text-align: center;
  width: 26px;
}
.left-side-toc ol li.active::before {
  color: #111111;
  /* font-weight: 600; */
}

.left-side-toc ol li:hover,
.left-side-toc ol li.active {
  background: #f3f5ff;
}

.left-side-toc ol li:hover a,
.left-side-toc ol li:hover span {
  color: #111111;
}
.left-side-toc ol li.active a,
.left-side-toc ol li.active span {
  color: #111111;
  /* font-weight: 600; */
}

.left-side-toc ol li a,
.left-side-toc ol li span {
  font-size: 12px;
  color: #333333;
  line-height: 16px;
}
.left-side-toc ol li a {
  display: block;
  margin: -4px 0;
  padding: 4px 0;
}

.left-toc-heading {
  padding-left: 5px;
}

.left-toc-height {
  max-height: 344px;
}

@media only screen and (max-width: 1365px) {
  .left-toc-sticky {
    width: 200px !important;
    margin-left: -56px;
  }
  .left-side-toc {
    padding: 10px 3px;
    border-radius: 4px;
  }
  .left-toc-heading {
    padding-left: 7px;
    padding-right: 7px;
  }
  .left-toc-height {
    max-height: 356px;
  }
  .left-side-toc ol li {
    padding-left: 22px;
    line-height: 13px;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .left-side-toc ol li::before {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #333333;
    left: 0px;
    top: 0px;
    padding-top: 2px;
    padding-left: 7px;
  }
  .left-side-toc ol li a,
  .left-side-toc ol li span {
    padding: 0px;
    font-size: 12px;
    color: #333333;
    line-height: 16px;
    font-weight: 400;
  }

  .left-side-toc ol li a {
    padding: 2px 7px;
  }
}

@media only screen and (min-width: 1366px) {
  .left-toc-sticky {
    width: 230px !important;
    margin-left: -86px;
  }
  .left-side-toc {
    padding: 10px 3px;
    border-radius: 6px;
  }
  .left-toc-heading {
    padding-left: 7px;
    padding-right: 7px;
  }
  .left-toc-height {
    max-height: 356px;
  }
  .left-side-toc ol li {
    padding-left: 22px;
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .left-side-toc ol li::before {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #333333;
    left: 0px;
    top: 0px;
    padding-top: 2px;
    padding-left: 7px;
  }
  .left-side-toc ol li a,
  .left-side-toc ol li span {
    padding: 0px;
    font-size: 13px;
    color: #333333;
    line-height: 18px;
    font-weight: 400;
  }
  .left-side-toc ol li a {
    padding: 2px 7px;
  }
}

@media only screen and (min-width: 1440px) {
  .left-toc-sticky {
    width: 282px !important;
    margin-left: -138px;
  }
  .left-side-toc {
    padding: 16px 5px;
    border-radius: 10px;
  }
  .left-toc-heading {
    padding-left: 11px;
    padding-right: 11px;
  }
  .left-toc-height {
    max-height: 338px;
  }
  .left-side-toc ol li {
    padding-left: 22px;
  }
  .left-side-toc ol li a {
    padding: 2px 11px;
  }
  .left-side-toc ol li::before {
    left: 0px;
    padding-top: 2px;
    padding-left: 11px;
  }
  .exit-content-img span:before {
    content: none;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.blog-exit-back {
  background-position: -1169px -650px;
  width: 16px;
  height: 16px;
}
.blog-exit-info-icon {
  background-position: -1344px -640px;
  width: 24px;
  height: 24px;
}
.blog-exit-calendar-icon {
  background-position: -1438px -640px;
  width: 24px;
  height: 24px;
}
.blog-exit-skip-icon {
  background-position: -1263px -650px;
  width: 7px;
  height: 11px;
}
.blog-exit-copy-icon {
  background-position: -969px -552px;
  width: 16px;
  height: 16px;
}
.blog-exit-coupon-icon {
  /* background-position: -1060px -551px; */
  background-position: -1248px -551px;
  width: 20px;
  height: 18px;
}
.blog-exit-coupon-small-icon {
  background-position: -1248px -551px;
  width: 19px;
  height: 18px;
}
.blog-exit-coupon-arrow-icon {
  background-position: -1156px -553px;
  width: 16px;
  height: 16px;
}
.blog-exit-download-icon {
  background-position: -33px -635px;
  width: 20px;
  height: 20px;
}

.blog-exit-download-icon-small {
  background-position: -33px -635px;
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 768px) {
  .align-items-none {
    align-items: unset;
  }
}

.series-list {
  counter-increment: series-counter;
  display: flex;
  align-items: baseline;
}

.series-list-item {
  padding-right: 8px;
}

.series-list-item::before {
  content: counter(series-counter) !important;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: #586feb;
  background-color: #dee2fb;
  text-align: center;
}

@media (max-width: 576px) {
  .blog-breadcumbs-mobile-width {
    max-width: calc(100vw - 100px);
    min-width: calc(100vw - 100px);
  }
}
.lead-form-input-margin {
  margin-bottom: 16px;
}

div#fc_frame {
  bottom: 0px !important;
  right: -10px !important;
}

.tooltip-bottom::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 10px;
  left: 100%;
  transform: rotate(45deg);
  background: #ffffff;
  margin-left: -5px;
  border-width: 1px;
  border-style: solid;
  border-color: #718aa566 #718aa566 transparent transparent;
}
.top-banner-styles img {
  @apply !w-full !h-auto;
}
/* purgecss end ignore */

.widgetYellowText span {
  color: #FFC632;
}
