
/* chat icon */
.chatToolTipDiv {
    width: 240px;
    padding: 12px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 2px 0px #869AAD;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #1E2D3E;
    margin-right: 8px;
}

#toolTipClose {
    width: 5.8px;
    height: 5.8px;
    color: #718AA5;
    position: absolute;
    top: 0px;
    right: 4.1px;
}
.phone-icon-circle {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 112px;
    border: 2.5px solid #D03070;
    background-color: #ffffff;
}

@media screen and (max-width: 766px) {
    /* chat icon */
    #chatToolTipMobile {
        width: 224px;
        padding: 8px;
        border-radius: 7px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 2px 0px #869AAD;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #1E2D3E;
        margin-right: 8px;
    }

    #whatsAppChatButton {
        width: 50px;
        height: 50px;
    }
    

}